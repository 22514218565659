<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.colaborador.abas.dados_colaborador') }}
      </v-tab>
      <v-tab
        :disabled="!(usuarioSistema && $refs['dados'].$refs.form.validate())"
      >
        {{ $t('modulos.colaborador.abas.usuario_sistema') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.colaborador.abas.documentos_colaborador') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-colaborador
          ref="dados"
          :form="form"
          :valido="validoColaborador"
          :usuario-selecionado="usuarioSelecionado"
          @usuario="usuarioValor"
          @validacao-colaborador="validacaoColaborador"
        />
      </v-tab-item>
      <v-tab-item>
        <formulario-usuario
          :id="usuarioId"
          ref="usuario"
          :origem="origem"
          :form="form"
          :valido-usuario="validoUsuario"
          :nome-colaborador-usuario="nomeColaboradorUsuario"
          :flag-adiciona-participante-logado-usuario="
            flagAdicionaParticipanteLogadoUsuario
          "
          :setor-colaborador="form.setorId"
          @form-usuario="addFormUsuario"
          @validacao-usuario="validacaoUsuario"
        />
      </v-tab-item>
      <v-tab-item>
        <documentos-colaborador :form="form" />
      </v-tab-item>
    </v-tabs-items>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ColaboradorService from '@common/services/cadastros/ColaboradorService';
import { ColaboradorModel } from '@common/models/cadastros/ColaboradorModel';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import DadosColaborador from './components/DadosColaborador.vue';
import DocumentosColaborador from './components/DocumentosColaborador.vue';
import FormularioUsuario from '../usuario/formulario.vue';
import helpers from '@common/utils/helpers';
import moment from 'moment';
export default {
  components: { DadosColaborador, FormularioUsuario, DocumentosColaborador },
  props: ['id'],
  data() {
    return {
      usuarioSistema: false,
      flagAdicionaParticipanteLogadoUsuario: false,
      usuarioSelecionado: false,
      usuarioId: null,
      nomeColaboradorUsuario: null,
      origem: 'colaborador',
      indiceAba: 0,
      validoColaborador: false,
      validoUsuario: false,
      form: new ColaboradorModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.colaborador.titulos.editar');
      return this.$t('modulos.colaborador.titulos.novo');
    },
  },
  watch: {
    'form.nome'() {
      this.nomeColaboradorUsuario = this.form.nome;
    },
  },
  mounted() {
    this.flagAdicionaParticipanteLogadoUsuario = true;
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Colaborador', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Colaborador', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ColaboradorService.buscar(this.id)
        .then((res) => {
          const data = {
            ...res.data,
            horarioInicioIntervalo: moment(
              res.data.horarioInicioIntervalo,
              'h:mm A'
            ).format('HH:mm'),
            horarioInicioTrabalho: moment(
              res.data.horarioInicioTrabalho,
              'h:mm A'
            ).format('HH:mm'),
            horarioTerminoIntervalo: moment(
              res.data.horarioTerminoIntervalo,
              'h:mm A'
            ).format('HH:mm'),
            horarioTerminoTrabalho: moment(
              res.data.horarioTerminoTrabalho,
              'h:mm A'
            ).format('HH:mm'),
          };
          this.form = new ColaboradorModel(data);
          this.validoColaborador = true;
          if (res.data.usuario?.id) {
            this.usuarioSelecionado = true;
            this.usuarioId = res.data.usuario.id;
          }
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.colaborador.erros.id_invalido'));
        })
        .finally(() => {
          this.form.setorId = new DropdownModel(this.form.setorId);
          this.form.cargoFuncaoId = new DropdownModel(this.form.cargoFuncaoId);
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      this.$refs['dados']?.validacaoForm();
      if (this.usuarioSistema) this.$refs['usuario']?.validacaoForm();

      if (!this.validoColaborador) return;
      if (this.usuarioSistema && !this.validoUsuario) {
        if (this.indiceAba == 0) return (this.indiceAba = 1);
        this.form.usuario.participantes.length <= 0
          ? this.toastAlerta(
              this.$t(`geral.erros.participanteDeveSerInformado`)
            )
          : this.toastAlerta(this.$t(`geral.erros.preenchaDadosdoUsuario`));
        return;
      }
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      ColaboradorService.salvar(this.form.request)
        .then(() => {
          this.$router.push({ name: 'colaborador' });
          this.toastSucesso(this.$t(`modulos.colaborador.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'colaborador' });
      });
    },
    usuarioValor(v) {
      this.usuarioSistema = v;
    },
    addFormUsuario(formUsuario) {
      this.form.usuario = {
        ...formUsuario,
        flagAdministrador: false,
        colaboradorId: null,
        participanteContatoId: null,
      };
    },
    validacaoColaborador: function (valido) {
      this.validoColaborador = valido;
    },
    validacaoUsuario: function (valido) {
      this.validoUsuario = valido;
    },
  },
};
</script>
