<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao class="mt-3" @click="abreModalAnexo">
        {{ $t('modulos.orcamentos.formulario.anexar_arquivo') }}
      </botao-padrao>
    </div>
    <!-- TODO: ! Aguardando verificar se essa tabela possui filtro, o que eu acho que n -->
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="documentosColaboradorDataFormatada"
      :mostraHeader="false"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      sem-paginacao
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="fazerDownloadArquivo(slotProps.data)"
            >
              {{ $t('geral.botoes.download') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="editarDocumento(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>

    <AnexoModal
      ref="modal-anexo"
      :form="form"
      @novoArquivo="novoArquivoAnexo"
      @editar-arquivo="editarArquivoAnexo"
    />
  </div>
</template>
<script>
import AnexoModal from './modais/AnexoModal.vue';
import helpers from '@common/utils/helpers';
import moment from 'moment';
export default {
  components: {
    AnexoModal,
  },
  props: {
    form: {},
    apenasVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: this.form.colaboradorDocumentos,
        colunas: [
          {
            value: 'nomeExtensaoArquivo',
            text: this.$t('modulos.colaborador.tabela.nome'),
            sortable: true,
          },
          {
            value: 'tipoDocumento.nome',
            text: this.$t('modulos.colaborador.tabela.tipo_documento'),
            sortable: true,
          },
          {
            value: 'valor',
            text: this.$t('modulos.colaborador.tabela.valor_documento'),
            sortable: true,
          },
          {
            value: 'validade',
            text: this.$t('modulos.colaborador.tabela.validade_documento'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      documentoSelecionado: null,
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },

    documentosColaboradorDataFormatada() {
      if (!this.tabela.dados) return;
      return this.tabela.dados.map((doc) => ({
        ...doc,
        validade: doc.validade
          ? moment(doc.validade).format('DD/MM/YYYY')
          : null,
      }));
    },
  },
  watch: {
    'form.colaboradorDocumentos': function () {
      this.tabela.dados = this.form.colaboradorDocumentos;
    },
  },
  methods: {
    editarDocumento(doc) {
      const documento = {
        ...doc,
        validade: moment(doc.validade, 'DD/MM/YYYY').format(
          'YYYY-MM-DDTHH:mm:ss.SSS'
        ),
      };
      this.documentoSelecionado = documento;

      this.$refs['modal-anexo'].abrirModal();
      setTimeout(() => {
        this.$refs['modal-anexo'].receberInformacoesDocumento(
          this.documentoSelecionado
        );
      }, 100);
    },

    abreModalAnexo() {
      this.$refs['modal-anexo'].abrirModal();
    },

    novoArquivoAnexo(arquivo) {
      let arquivoRefatorado = {
        ...arquivo,
        tipoDocumento: {
          id: arquivo.tipoDocumento.value,
          nome: arquivo.tipoDocumento.text,
        },
      };

      this.form.colaboradorDocumentos.push(arquivoRefatorado);
    },

    editarArquivoAnexo: function (novoArquivo) {
      const index = this.form.colaboradorDocumentos.findIndex(
        (doc) => doc.id === this.documentoSelecionado.id
      );

      let documentoOriginal = this.form.colaboradorDocumentos[index];

      documentoOriginal = Object.assign(documentoOriginal, novoArquivo);

      this.form.colaboradorDocumentos[index] = documentoOriginal;

      this.form.colaboradorDocumentos = [...this.form.colaboradorDocumentos];
    },

    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.colaboradorDocumentos.splice(
            this.form.colaboradorDocumentos.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
      });
    },
    fazerDownloadArquivo: async function (item) {
      helpers.baixarBase64(item.anexo, item.nomeExtensaoArquivo);
    },
  },
};
</script>
