<template>
  <div>
    <v-form ref="form" class="row mt-4">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.nome')"
        obrigatorio
        trim
        :max="150"
      />
      <input-text
        v-model="form.cpf"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.cpf')"
        :mascara="mascaraCPF"
        obrigatorio
      />
      <input-date
        v-model="form.dataNascimento"
        class="col-12 col-md-3"
        apenas-datas-passadas
        :label="$t('modulos.colaborador.formulario.data_nascimento')"
        obrigatorio
      />
      <input-select
        v-model="form.genero"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.genero')"
        :options="opcoes.genero"
        obrigatorio
      />
      <input-text
        v-model="form.emailCorporativo"
        class="col-12 col-md-4"
        :label="$t('modulos.colaborador.formulario.email_corporativo')"
        email
        :max="200"
      />
      <input-cargo
        v-model="form.cargoFuncaoId"
        class="col-12 col-md-4"
        :label="$t('modulos.colaborador.formulario.cargo')"
        outlined
        :multiplos="false"
        obrigatorio
      />
      <input-setor
        v-model="form.setorId"
        class="col-12 col-md-4"
        :label="$t('modulos.colaborador.formulario.setor')"
        outlined
        :multiplos="false"
        obrigatorio
      />

      <input-time
        v-model="form.horarioInicioTrabalho"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.horario_inicio')"
        obrigatorio
        :regras-personalizadas="regraInputsJornadaEntrada()"
      />

      <input-time
        v-model="form.horarioInicioIntervalo"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.horario_inicio_intervalo')"
        obrigatorio
        :regras-personalizadas="regraInputsJornadaIntervalo()"
      />
      <input-time
        v-model="form.horarioTerminoIntervalo"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.horario_termino_intervalo')"
        obrigatorio
        :regras-personalizadas="regraInputsJornadaIntervalo()"
      />
      <input-time
        v-model="form.horarioTerminoTrabalho"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.horario_termino')"
        obrigatorio
        :regras-personalizadas="regraInputsJornadaSaida()"
      />
      <v-checkbox
        v-model="usuarioSistema"
        class="col-12 col-md-3"
        :label="$t('modulos.colaborador.formulario.usuario_sistema')"
        hide-details
      />
      <v-checkbox
        v-model="form.restringirAcessoPorIP"
        class="col-12 col-md-3"
        hide-details
        :label="$t('modulos.colaborador.formulario.restringir_por_ip')"
        :clearable="false"
      />
      <v-checkbox
        v-model="form.restringirAcessoPorHorarioTrabalho"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.colaborador.formulario.restringir_acesso_horario_trabalho'
          )
        "
        hide-details
      />
    </v-form>
  </div>
</template>
<script>
import InputSetor from '@/components/inputs/especificos/InputSetor';
import InputCargo from '@/components/inputs/especificos/InputCargo';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { EnumModel } from '@common/models/geral/EnumModel';
export default {
  name: 'DadosColaborador',
  components: { InputSetor, InputCargo },
  props: {
    form: {},
    id: { type: String, default: null },
    usuarioSelecionado: { type: Boolean, default: false },
  },
  data() {
    return {
      usuarioSistema: false,
      valido: false,
      opcoes: {
        genero: [],
      },
      mascaraCPF: '###.###.###-##',
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.colaborador.titulos.editar');
      return this.$t('modulos.colaborador.titulos.novo');
    },
  },
  watch: {
    usuarioSistema() {
      this.$emit('usuario', this.usuarioSistema);
    },
    usuarioSelecionado() {
      if (this.usuarioSelecionado) this.usuarioSistema = true;
    },
  },
  mounted() {
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    this.buscarEnumeradorGenero();
  },
  methods: {
    buscarEnumeradorGenero: function () {
      EnumeradorService.buscar('EnumGeneroColaborador').then((res) => {
        this.opcoes.genero = new EnumModel(res, 'EnumGeneroColaborador');
      });
    },
    validacaoForm: function () {
      this.$refs.form.validate();
      this.$emit('validacao-colaborador', this.$refs.form.validate());
    },
    regraInputsJornadaEntrada() {
      let erros = [];
      if (this.form.horarioInicioTrabalho > this.form.horarioInicioIntervalo)
        erros.push(
          this.$t('inputs.rules.horario_inicio_maior_inicio_intervalo')
        );
      if (this.form.horarioInicioTrabalho > this.form.horarioTerminoIntervalo)
        erros.push(
          this.$t('inputs.rules.horario_inicio_maior_termino_intervalo')
        );
      if (this.form.horarioInicioTrabalho > this.form.horarioTerminoTrabalho)
        erros.push(this.$t('inputs.rules.horario_inicio_maior_saida'));
      return erros;
    },
    
    regraInputsJornadaSaida() {
      let erros = [];
      if (this.form.horarioTerminoTrabalho < this.form.horarioInicioTrabalho)
        erros.push(this.$t('inputs.rules.horario_saida_menor_entrada'));
      if (this.form.horarioTerminoTrabalho < this.form.horarioInicioIntervalo)
        erros.push(
          this.$t('inputs.rules.horario_saida_menor_inicio_intervalo')
        );
      if (this.form.horarioTerminoTrabalho < this.form.horarioTerminoIntervalo)
        erros.push(
          this.$t('inputs.rules.horario_saida_menor_termino_intervalo')
        );
      return erros;
    },
    regraInputsJornadaIntervalo() {
      let erros = [];
      if (this.form.horarioInicioIntervalo > this.form.horarioTerminoIntervalo)
        erros.push(
          this.$t(
            'inputs.rules.horario_inicio_intervalo_maior_termino_intervalo'
          )
        );
      return erros;
    },
  },
};
</script>
