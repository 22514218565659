import _ from 'lodash';


export class ColaboradorModel {
  constructor({
    id,
    nome,
    cpf,
    dataNascimento,
    emailCorporativo,
    genero,
    cargoFuncao,
    setor,
    horarioInicioTrabalho,
    horarioTerminoTrabalho,
    horarioInicioIntervalo,
    horarioTerminoIntervalo,
    usuario,
    colaboradorDocumentos,
    restringirAcessoPorIP,
    restringirAcessoPorHorarioTrabalho
    }) {
    this.id = id;
    this.nome = nome;
    this.cpf = cpf;
    this.dataNascimento = dataNascimento;
    this.emailCorporativo = emailCorporativo;
    this.genero = genero;
    this.cargoFuncaoId = cargoFuncao;
    this.setorId = setor;
    this.horarioInicioTrabalho = horarioInicioTrabalho;
    this.horarioTerminoTrabalho = horarioTerminoTrabalho;
    this.horarioInicioIntervalo = horarioInicioIntervalo;
    this.horarioTerminoIntervalo = horarioTerminoIntervalo;
    this.usuario = usuario;
    this.colaboradorDocumentos = colaboradorDocumentos ?? []
    this.restringirAcessoPorIP = restringirAcessoPorIP;
    this.restringirAcessoPorHorarioTrabalho = restringirAcessoPorHorarioTrabalho;
    }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.cargoFuncaoId = this.cargoFuncaoId?.value;
    retorno.setorId = this.setorId?.value;
    retorno.colaboradorDocumentos?.forEach(
      (anexo) => (anexo.id = !anexo.flagJaExistente ? null : anexo.id)
    );
   /*  if (!retorno.id) {
      retorno.colaboradorDocumentos = _.cloneDeep(retorno.anexos);
      retorno.anexos = null;
    } */
    if (this.usuario) {
      retorno.usuario.participantes = this.usuario?.participantes?.map((el) => {
        return {
          participanteId: el.participante.value,
          setorId: el.setor?.value,
        };
      });
    }
    retorno.colaboradorDocumentos = retorno.colaboradorDocumentos.map((el) => {
      return {
        tipoDocumentoId: el.tipoDocumento?.id,
        valor: el.valor,
        validade: el.validade,
        anexo: el.anexo,
        nomeExtensaoArquivo: el.nomeExtensaoArquivo,
    };
    });
    return retorno;
  }
}
