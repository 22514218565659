<template>
  <modal-padrao
    ref="modal-anexo"
    max-width="900px"
    max-height="650px"
    :titulo="$t('modulos.orcamentos.formulario.abas.anexos')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    @ok="salvar"
  >
    <v-form ref="form" class="py-10 row">
      <input-select
        v-model="form.tipoDocumento"
        class="col-12 col-md-6"
        :label="$t('modulos.colaborador.formulario.documento_tipo')"
        :placeholder="$t('modulos.colaborador.formulario.documento_tipo')"
        obrigatorio
        :options="seletorTipoDocumento"
        retornar-objeto
      />
      <input-text
        v-model="form.valor"
        :class="!form.id ? 'col-12 col-md-4' : 'col-12 col-md-3'"
        :label="$t('modulos.colaborador.formulario.documento_valor')"
        obrigatorio
      />

      <input-date
        v-model="form.validade"
        class="col-12 col-md-3"
        apenas-datas-futuras-sem-hoje
        :label="$t('modulos.colaborador.formulario.documento_validade')"
      />

      <input-file
        v-if="input"
        v-model="form.anexo"
        accept="*"
        class="col-12 col-md-6"
        :label="$t('modulos.orcamentos.formulario.adicionar_arquivo')"
        obrigatorio
        @clear="desvincularAnexo"
        @nome-imagem="nomeImagem"
        @handle-upload="handlerUploadInputFile"
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import InputFile from '@/components/inputs/InputFile.vue';
import InputSelect from '@/components/inputs/InputSelect.vue';
import helpers from '@common/utils/helpers';
import TipoDocumentoService from '@common/services/cadastros/TipoDocumentoService';
export default {
  components: { InputFile, InputSelect },

  data() {
    return {
      seletorTipoDocumento: [],
      form: {},
      input: false,
      valido: false,
      tipoDocumento: {},
      deveEditar: false,
      arquivo: null,
    };
  },
  watch: {
    form: {
      handler() {
        if (this.$refs['form']) {
          this.valido = this.$refs.form.validate() && this.form.anexo;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.listarTipoDocumento();
  },
  methods: {
    receberInformacoesDocumento: function (info) {
      this.deveEditar = true;
      this.form.tipoDocumento = info.tipoDocumento.id;
      this.form.valor = info.valor;
      this.form.validade = info.validade;
      this.form.nomeExtensaoArquivo = info.nomeExtensaoArquivo;
      this.form.anexo = this.base64ParaArquivo(
        info.anexo,
        info.nomeExtensaoArquivo
      );
    },
    base64ParaArquivo: function (base64String, nomeArquivo) {
      let parts = base64String.split(';base64,');
      let mimeType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new File([uInt8Array], nomeArquivo, { type: mimeType });
    },
    arquivoParaBase64: function (arquivo) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(arquivo);
      });
    },
    resetarValidacao() {
      this.$refs['form'].resetValidation();
    },
    desvincularAnexo() {
      this.form.anexo = null;
    },
    abrirModal: function () {
      this.iniciarForm();

      this.input = true;

      this.$refs['modal-anexo'].abrirModal();
      setTimeout(() => {
        this.resetarValidacao();
      }, 500);
    },
    iniciarForm() {
      this.form = {
        id: helpers.gerarNumeroAleatorio(),
        valor: null,
        validade: null,
        tipoDocumento: null,
        nomeExtensaoArquivo: null,
        anexo: null,
      };
    },
    handlerUploadInputFile(arquivo) {
      this.form.anexo = arquivo;
    },
    nomeImagem: function (nomeArquivo) {
      this.form.nomeExtensaoArquivo = nomeArquivo;
    },
    async salvar() {
      let base64String = await this.arquivoParaBase64(this.form.anexo);

      if (this.deveEditar) {
        const buscarDocumento = await TipoDocumentoService.buscar(
          this.form.tipoDocumento
        );

        this.form = {
          ...this.form,
          tipoDocumento: {
            id: buscarDocumento.data.id,
            nome: buscarDocumento.data.nome,
            descricao: buscarDocumento.data.descricao,
          },
          anexo: base64String,
        };

        this.$emit('editar-arquivo', this.form);

        this.input = false;
        this.iniciarForm();
        this.$refs['modal-anexo'].fecharModal();
      } else {
        this.form = {
          ...this.form,

          anexo: base64String,
        };

        this.$emit('novoArquivo', this.form);

        this.input = false;
        this.iniciarForm();
        this.$refs['modal-anexo'].fecharModal();
      }
    },
    listarTipoDocumento: function () {
      let parametros = {
        page: 1,
        amountPerPage: 1000,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoDocumentoService.listar(parametros)
        .then((res) => {
          if (res.data.items.length) {
            res.data.items.forEach((item) => {
              this.seletorTipoDocumento.push({
                value: item.id,
                text: item.nome,
              });
            });
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
